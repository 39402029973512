(function () {
    'use strict';

    angular
        .module('madApp')
        .config(stateConfig)
        .run(stateRun);

    stateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];
    stateRun.$inject = ['$rootScope', '$state'];

    function stateRun($rootScope, $state) {
        $rootScope.state = $state;
      }

    function stateConfig($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state('vl.dashboards.revenue', {
                parent: 'vl.dashboards',
                url: '/revenue',
                data: {
                    pageTitle: 'home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/vl/dashboards/revenue/views/revenue.html',
                        controller: 'dorRevenueController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('revenue');
                        return $translate.refresh();
                    }]
                }
            })
            .state('vl.dashboards.revenue.collections', {
                url: '/collections/:type/:id',
                params : {fromDate:null, toDate:null},
                templateUrl: 'app/vl/dashboards/revenue/views/revenue.collections.html'
            })
            .state('vl.dashboards.revenue.dcb', {
                url: '/dcb/total/:type/:id',
                params : {fromDate:null, toDate:null},
                templateUrl: 'app/vl/dashboards/revenue/views/revenue.dcb.html'
            })
            .state('vl.dashboards.revenue.dcbprivate', {
                url: '/dcb/private/:type/:id',
                params : {fromDate:null, toDate:null},
                templateUrl: 'app/vl/dashboards/revenue/views/revenue.dcb.html'
            })
            .state('vl.dashboards.revenue.dcbstate', {
                url: '/dcb/state/:type/:id',
                params : {fromDate:null, toDate:null},
                templateUrl: 'app/vl/dashboards/revenue/views/revenue.dcb.html'
            })
            .state('vl.dashboards.revenue.dcbcentral', {
                url: '/dcb/central/:type/:id',
                params : {fromDate:null, toDate:null},
                templateUrl: 'app/vl/dashboards/revenue/views/revenue.dcb.html'
            })
            .state('vl.dashboards.revenue.dcbvacant', {
                url: '/dcb/vacant/:type/:id',
                params : {fromDate:null, toDate:null},
                templateUrl: 'app/vl/dashboards/revenue/views/revenue.dcb.html'
            })
            .state('vl.dashboards.revenue.dcbcourt', {
                url: '/dcb/court/:type/:id',
                params : {fromDate:null, toDate:null},
                templateUrl: 'app/vl/dashboards/revenue/views/revenue.dcb.html'
            })
            .state('vl.dashboards.revenue.receipts', {
                url: '/receipts/:type/:id',
                params : {fromDate:null, toDate:null},
                templateUrl: 'app/vl/dashboards/revenue/views/revenue.receipts.html'
            })
            .state('vl.dashboards.revenue.top10', {
                url: '/top10/:type/:id',
                params : {fromDate:null, toDate:null},
                templateUrl: 'app/vl/dashboards/revenue/views/revenue.top10.html'
            })
            .state('vl.dashboards.revenue.bottom10', {
                url: '/bottom10/:type/:id',
                params : {fromDate:null, toDate:null},
                templateUrl: 'app/vl/dashboards/revenue/views/revenue.bottom10.html'
            })
            .state('vl.dashboards.revenue.top100defaulters', {
                url: '/top100defaulters/:type/:id',
                params : {fromDate:null, toDate:null},
                templateUrl: 'app/vl/dashboards/revenue/views/revenue.top100defaulters.html'
            })
            .state('vl.dashboards.revenue.collectionranking', {
                url: '/collectionranking/:parentofficertype/:officertype/:type/:id/',
                params : {fromDate:null, toDate:null},
                templateUrl: 'app/vl/dashboards/revenue/views/revenue.collectionranking.html'
            })
            .state('blocked', {
                url: '/blocked',
                data: {
                    pageTitle: 'Access Denied'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/vl/dashboards/revenue/views/blocked.html',
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('revenue');
                        return $translate.refresh();
                    }]
                }
            });

         $urlRouterProvider.when('/revenue', '/revenue/collections/DISTRICT/');

    }
})();
