angular
    .module('madApp')
    .controller('RevenueTableDirectiveController', RevenueTableDirectiveController)
;

RevenueTableDirectiveController.$inject = ['$scope', '$state', '$stateParams', '$filter', 'ParseLinks', 'AlertService','RevenueServices'];
function RevenueTableDirectiveController($scope, $state, $stateParams, $filter, ParseLinks, AlertService,RevenueServices) {

    
    $scope.revenueOfficerOrInspector = false;
    if($state.current.name === "vl.dashboards.revenue.collections" || $state.current.name === "vl.dashboards.revenue.dcbprivate" ||
    $state.current.name === "vl.dashboards.revenue.dcbstate" || $state.current.name === "vl.dashboards.revenue.dcbcentral" ||
    $state.current.name === "vl.dashboards.revenue.dcbcourt" || $state.current.name === "vl.dashboards.revenue.dcbvacant" ||
    $state.current.name === "vl.dashboards.revenue.dcb" ||  $state.current.name === "vl.dashboards.revenue.top10"
    ||  $state.current.name === 'vl.dashboards.revenue.bottom10' ){
      $scope.revenueOfficerOrInspector = true;

    }


   var revnueWardTypes = ['ULB_WARD','ULB_BILL','ULB_REVENUE_INSPECTOR','ULB_REVENUE_OFFICER'];
   

   $scope.parentulbtype = 'ULB';
    if(revnueWardTypes.includes($scope.ulbtype)){
      $scope.parentulbtype = 'ULB_WARD';
    }else{
      $scope.parentulbtype = 'ULB';
    }
    $scope.checkULBWardType = function(){
      return $scope.parentulbtype === 'ULB_WARD';
    }
    $scope.checkSecretariatWardType = function(){
      return $scope.parentulbtype === 'ULB';
    }
  
    $scope.parseFloat = parseFloat;
    $scope.submit = function (key) {
         $state.go($state.current.name, {id: key,type:$scope.details.locType});
    };


    $scope.search = function(value){
      if(RevenueServices.getPropertyType() && $state.current.name==="vl.dashboards.revenue.top100defaulters"){
        if(value[1].value.indexOf(RevenueServices.getPropertyType())!= -1)
          return value;
      }else{
        return value;
      }
    }

    $scope.$watch("parentulbtype", function (newValue, oldValue) {
      if (newValue && newValue != oldValue) {
        if (newValue === "ULB") {
          $scope.ulbtype = "ULB";
        } else {
          $scope.ulbtype = "ULB_WARD";
        }
      }
    });

    $scope.send = function (type,key) {
        if(type && key){
            $state.go($state.current.name, {id: key,type:type});
        }
        else {
            $state.go($state.current.name,{id: null,type:null});
        }
    };

        $scope.setSortOrder = function(index,parentIndex){
          if(index!=undefined && index!=null){

            if($scope.details.records[parentIndex].infiniteScroll || $scope.details.records[parentIndex].modal){
              $scope.details.records[parentIndex].limit =50;
            }else{
              //$scope.showAll(parentIndex);
            }


            if($scope.details.records[parentIndex].sortIndex ===index){
                $scope.details.records[parentIndex].sortOrder = !$scope.details.records[parentIndex].sortOrder;
            }else{
                $scope.details.records[parentIndex].sortOrder = false;
            }
            $scope.details.records[parentIndex].sortIndex = index;
          }
        };

        $scope.compare = function (v1,v2) {
          if (isNaN(v1.value) === false || isNaN(v2.value) === false) {
            return (parseFloat(v1.value) > parseFloat(v2.value)) ? -1 : 1;
          }
          if(v1.value!=undefined && v2.value!=undefined){
            // return v1.value.localeCompare(v2.value);
            var ax = [], bx = [];
            var a = v1.value;
            var b = v2.value;
            a.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
            b.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });

            while(ax.length && bx.length) {
                var an = ax.shift();
                var bn = bx.shift();
                var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
                if(nn) return nn;
            }

            return ax.length - bx.length;
          }

        };

        $scope.$watch('officertype', function (newValue, oldValue) {
          if (newValue && newValue!=oldValue && $state.params.officertype!=undefined) {
              var stateParams = {};
              stateParams["id"] = $state.params.id;
              stateParams["officertype"] = $scope.officertype;
              stateParams["parentofficertype"] = $scope.parentofficertype;
              if ($state.params.fromDate) {
                stateParams["fromDate"] = $state.params.fromDate;
              }
              if ($state.params.toDate) {
                stateParams["toDate"] = $state.params.toDate;
              }
              $state.go($state.current.name, stateParams);
          }
        });

        $scope.$watch('parentofficertype', function (newValue, oldValue) {
         
          if (newValue && newValue != oldValue) {
              if(newValue == 'ward'){
                  $scope.officertype = 'revenueinspector';
              }else{
                  $scope.officertype = 'wardsecretary';
              }
              var stateParams = {};
              stateParams["id"] = $state.params.id;
              stateParams["officertype"] = $scope.officertype;
              stateParams["parentofficertype"] = $scope.parentofficertype;
              if ($state.params.fromDate) {
                stateParams["fromDate"] = $state.params.fromDate;
              }
              if ($state.params.toDate) {
                stateParams["toDate"] = $state.params.toDate;
              }
              $state.go($state.current.name, stateParams);  
          }
        });

        $scope.getColorClass = function(color,code){
          if(color==="color1" && code==1){
            return true;
          }else if(color==="color2" && code==2){
            return true;
          }else{
            return false;
          }
        }

        $scope.loadMore = function(tableIndex) {
          if($scope.details.records[tableIndex].infiniteScroll){
            if ($scope.details.records[tableIndex].limit + 50 < $scope.details.records[tableIndex].recordData.length) {
                $scope.details.records[tableIndex].limit += 50;
            } else {
                $scope.details.records[tableIndex].limit = $scope.details.records[tableIndex].recordData.length;
            }
          }
       };
}
